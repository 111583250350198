$color_1: #fff;

@keyframes move {
  0%,
  5% {
    left: -32px;
    width: 16px;
  }
  15%,
  20% {
    left: -32px;
    width: 48px;
  }
  30%,
  35% {
    left: 0px;
    width: 16px;
  }
  45%,
  50% {
    left: 0px;
    width: 48px;
  }
  60%,
  65% {
    left: 32px;
    width: 16px;
  }
  75%,
  80% {
    left: 32px;
    width: 48px;
  }
  95%,
  100% {
    left: 64px;
    width: 16px;
  }
}
.loader {
  width: 16px;
  height: 16px;
  position: fixed;
  border-radius: 50%;
  color: #c6c5c5;
  background: currentColor;
  box-shadow:
    32px 0,
    -32px 0,
    64px 0;
  z-index: 9999;
  top: 4%;
  left: 50%;
  transform: translate(-50%, -50%);
  &::after {
    content: '';
    position: absolute;
    top: 0;
    width: 16px;
    height: 16px;
    border-radius: 10px;
    background: #ff7f50;
    animation: move 2s linear infinite alternate;
  }
}

.bg-charcoal{
  background-color: #333333;
}

.clickable-icon{
  cursor: pointer;
}

.wrap-label{
  word-wrap: break-word;
  white-space: normal;
  margin: 0;
  padding: 0;
}

.sidebar{
  &.hide{
    width: 0;

    .sidebar-inner{
      &.hide{
        display: none;
      }
    }
  }
}

.collapse-content{
  overflow: visible;
  padding: 0 1rem 0 1rem;
  transition: all 0.3s;
  margin-left: 0;
}

.toggle-sidebar-button {
  position: fixed;
  bottom: 0;
  left: 0;
  margin: 1rem;
  z-index: 1050;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  transition: all 0.3s ease-in-out;

  &.sidebar-visible {
    background-color: white;
    color: black;
  }

  &.sidebar-hidden {
    background-color: black;
    color: white;
  }

  &:hover {
    cursor: pointer;

    .hover-text {
      opacity: 1;
    }
  }

  .hover-text {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.75);
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    white-space: nowrap;
    margin-left: 5px;
  }
}

/*
 * Sidebar
*/
.sidebar {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  max-height: 100%;
  overflow-y: auto;
  width: 100%;
  @include transition(max-width 0.3s);

  @include media-breakpoint-up(md) {
    width: 100%;
    max-width: 340px;
  }

  .nav {
    white-space: nowrap;
  }

  .nav-item {
    border: $border-width solid transparent;
    &.active {
      & > .nav-link {
        color: $light;
        background-color: #faa11b;
        border: $border-width solid lighten($dark, 20%);
      }
    }
    .nav-link {
      color: $light;
      border: $border-width solid #333333;
      &:hover {
        color: $light;
        background-color: #f99807;
      }
      &.active {
        color: $white;
      }
    }
    margin-bottom: 0.2rem;
  }

  .nav-link + .multi-level {
    margin-top: 0.2rem;
  }

  .nav-link {
    font-size: $font-size-base;
    vertical-align: middle;
    padding: 0.55rem 0.75rem;
    @include border-radius($border-radius);

    .sidebar-icon {
      margin-right: 0.5rem;
      color: $white;
      span {
        min-width: 22px;
      }
      svg {
        min-width: 22px;
      }
      &.svg-icon {
        margin-right: 14px;
      }
    }

    .link-arrow {
      font-size: $font-size-sm;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 0.1rem rgba(#4c5680, 0.2);
    }
  }
  .nav-link.active {
    color: $primary;
  }

  .multi-level {
    .nav-link {
      padding-left: 45px;
    }
  }

  .sidebar-text,
  .link-arrow,
  .badge,
  .notification-count {
    opacity: 1;
    @include transition(opacity 0.3s);
  }

  @include media-breakpoint-up(md) {
    .sidebar-text-contracted {
      display: none;
      @include transition(opacity 0.3s);
    }

    &.contracted {
      .sidebar-text,
      .link-arrow,
      .badge {
        opacity: 0;
      }

      .notification-count {
        opacity: 1;
        position: absolute;
        top: 0;
        left: 35px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        font-size: 0.7rem;
        padding: 0.2rem;
      }

      .nav-item {
        position: relative;
        width: 47px;
        &.active {
          & > .nav-link {
            background-color: $primary;
          }
        }
        .nav-link {
          &:hover {
            background-color: $primary;
          }
        }
      }

      .sidebar-text-contracted {
        display: inline;
      }

      .multi-level .nav-link {
        padding-left: 17px;
      }

      .nav-item {
        white-space: nowrap;
      }

      .sidebar-icon {
        text-align: center;
      }

      @include media-breakpoint-up(md) {
        & + .content {
          margin-left: 95px;
        }
      }

      max-width: 95px;
    }
  }
}

.sidebar-inner {
  position: relative;
  overflow-y: hidden;
  .accordion-button {
    &::after {
      background-image: none;
      font-family: $font-awesome-5;
      font-size: $font-size-sm;
      font-weight: 900;
      height: 1.25rem;
      width: 0.5rem;
    }
    &:not(.collapsed) {
      background-color: #faa11b;
      &::after {
        transform: rotate(90deg);
        transition: all 0.2s ease;
      }
    }
  }

  .accordion {
    padding-top: 0.25rem;
  }

  .accordion-body {
    padding: 0.5rem 0 0 0;
  }

  .accordion-collapse {
    border: 0;
  }
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.user-card {
  border-bottom: 0.0625rem solid #2e3650;
}

@include media-breakpoint-down(sm) {
  .sidebar {
    width: 100%;
  }
}

.content {
  overflow: visible;
  padding: 0 $spacer 0 $spacer;
  @include transition(all 0.3s);

  @include media-breakpoint-up(md) {
    margin-left: 340px;
  }
}

.sidebar-toggle {
  font-size: 1.3rem;

  &:hover,
  &:focus {
    outline: none;
    box-shadow: none;
    background: $gray-400;
  }
}

.sidebar-transition-enter {
  opacity: 0;
}

.sidebar-transition-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}

.sidebar-transition-exit {
  opacity: 1;
}

.sidebar-transition-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800&display=swap');

// Bootstrap mixins and functions
@import '../../node_modules/bootstrap/scss/mixins';
@import '../../node_modules/bootstrap/scss/functions';

// Change variables here
@import 'volt/variables';

// Bootstrap
@import '../../node_modules/bootstrap/scss/bootstrap';

// Vendor
@import 'volt/vendor';

// volt mixins & functions
@import 'volt/mixins';
@import 'volt/functions';

// Utilities
@import 'volt/reboot';
@import 'volt/utilities';

// Layout
@import 'volt/layout';

// Components
@import 'volt/components';

// write your custom styles here!

.invalid-fields {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #fa5252;
}
.bold-600 {
  font-weight: 600;
}
.bold-700 {
  font-weight: 700;
}

.form-label {
  &.light-font {
    font-size: 12px;
  }
}

.search-block-width-400 {
  width: 400px;
}
.no-result-box {
  display: flex;
  align-items: center;
  justify-content: center;

  & span {
    font-weight: 800;
    margin-top: 20px;
  }
}

.thead-light {
  &.no-border-head {
    & th {
      border-style: none;
    }
  }
}

.text-xl-left,
.align-items-left {
  text-align: left;
}

.rest-day-dark {
  background-color: #f4f5f7;
  border-color: #efefef;
}

.no-border-radius {
  border-radius: 0;
}
.align-items-start{
  align-items: flex-start;
}

.break-long-words{
  word-wrap: break-word;
  white-space: normal;
  margin: 0;
  padding: 0;
}

.pull-right{
  float: right
}

.more-left-dropdown-menu {
  right: auto !important;
  left: -150px !important; 
}

.btn-secondary{
  color: #ffffff;
}
